import produce from "immer";
import {
  GET_VIDEO_LIST,
  GET_VIDEO_LIST_SUCCESS,
  GET_VIDEO_LIST_ERROR,
  GET_AISLE_LIST,
  GET_AISLE_LIST_SUCCESS,
  GET_AISLE_LIST_ERROR,
  ADD_VIDEO,
  ADD_VIDEO_SUCCESS,
  ADD_VIDEO_ERROR,
  SET_VIDEO_LIST,
  SET_VIDEO_SEARCH,
  SET_VIDEOS_SORT_FIELD,
  SET_VIDEOS_SORT_ORDER,
  SET_VIDEOS_PAGE
} from "./actionTypes";

const initialState = {
  allVideosList: null,
  loading: false,
  error: "",
  totalCount: 0,
  totalPages: 0,
  limit: 5,
  page: 1,
  sortOrder: "",
  sortField: "",
  search: "",
  aisle_list: null,
  aisleLoad: false,
  aisleError: "",
  addVideoLoad: false
};

const videosReducer = produce((state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_VIDEO_LIST:
      return {
        ...state,
        loading: true
      };

    //set ingredients list
    case GET_VIDEO_LIST_SUCCESS:
      return {
        ...state,
        allVideosList: payload.data,
        totalCount: payload.metadata.totalCount,
        totalPages: payload.metadata.totalPages,
        // page: payload.metadata.pages,
        limit: payload.metadata.limit,
        count: payload.metadata.count,
        loading: false
      };
    case GET_VIDEO_LIST_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    // set Videos search
    case SET_VIDEO_SEARCH:
      return {
        ...state,
        search: payload
      };
    // set Videos sortField
    case SET_VIDEOS_SORT_FIELD:
      return {
        ...state,
        sortField: payload
      };

    // set Videos sortOrder
    case SET_VIDEOS_SORT_ORDER:
      return {
        ...state,
        sortOrder: payload
      };
    // set Videos Page
    case SET_VIDEOS_PAGE:
      return {
        ...state,
        page: payload
      };
    case GET_AISLE_LIST:
      return {
        ...state,
        aisleLoad: true
      };
    case GET_AISLE_LIST_SUCCESS:
      return {
        ...state,
        aisle_list: payload,
        aisleLoad: false
      };
    case GET_AISLE_LIST_ERROR:
      return {
        ...state,
        aisleLoad: false,
        aisleError: payload
      };
    case ADD_VIDEO:
      return {
        ...state,
        addVideoLoad: true
      };
    case ADD_VIDEO_SUCCESS:
      return {
        ...state,
        addVideoLoad: false
      };
    case ADD_VIDEO_ERROR:
      return {
        ...state,
        addVideoLoad: false
      };

    case SET_VIDEO_LIST:
      return {
        ...state,
        allVideosList: payload
      };

    default:
      return state;
  }
});

export default videosReducer;
