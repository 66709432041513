import tags from "assets/images/Tag.png";
import tagActive from "assets/images/TagActive.png";
import activeSubscriber from "assets/images/active_subscriber.png";
import challengesOrg from "assets/images/challengeIconBig.png";
import challenges from "assets/images/challenges.png";
import faqActive from "assets/images/faq_bowl_active.png";
import faqDefault from "assets/images/faq_bowl_default.png";
import inActiveSubscriber from "assets/images/inActive_subscriber.png";
import ingredients from "assets/images/ingredients.png";
import ingredientsOrg from "assets/images/ingredients_orange.png";
import push_notificationsOrg from "assets/images/new_notification_icon.png";
import push_notifications from "assets/images/push_notifications.png";
import products from "assets/images/products.png";
import productOrg from "assets/images/productOrg.png";
import recipesOrg from "assets/images/recipes-orange.png";
import recipes from "assets/images/recipes.png";
import userOrg from "assets/images/user-orange.png";
import user from "assets/images/user.png";
import React, { useMemo } from "react";

import { Link, useLocation } from "react-router-dom";
import "./sideBar.css";
const SideBar = ({ openSidebar, isSidebar }) => {
  const location = useLocation();
  const pageTitle = location.pathname;
  const sidebarContent = useMemo(
    () => [
      {
        img: ingredients,
        img2: ingredientsOrg,
        name: "Ingredients",
        pathname: "/ingredients",
        activeTab: ["/ingredients"],
      },
      {
        img: recipes,
        img2: recipesOrg,
        name: "Recipes",
        pathname: "/recipes",
        activeTab: ["/recipes", "/new-recipes", "/edit-recipes"],
      },
      {
        img: tagActive,
        img2: tags,
        name: "Category",
        pathname: "/categories",
        activeTab: ["/categories"],
      },
      {
        img: challenges,
        img2: challengesOrg,
        name: "Meal Plans",
        pathname: "/meal-Plans",
        activeTab: ["/meal-Plans", "/new-meal-Plan", "/edit-meal-Plan"],
      },
      {
        img: products,
        img2: productOrg,
        name: "Products",
        pathname: "/products",
        activeTab: ["/products", "/add-product", "/edit-product"],
      },
      {
        img: push_notifications,
        img2: push_notificationsOrg,
        name: "Push Notifications",
        pathname: "/push-notifications",
        activeTab: ["/push-notifications"],
      },
      {
        img: faqDefault,
        img2: faqActive,
        name: "Faq",
        pathname: "/faq",
        activeTab: ["/faq"],
      },
      {
        img: user,
        img2: userOrg,
        name: "Users",
        pathname: "/users",
        activeTab: ["/users"],
      },
      // {
      //   img: inActiveSubscriber,
      //   img2: activeSubscriber,
      //   name: "Subscribers",
      //   pathname: "/subscribers",
      //   activeTab: ["/subscribers"],
      // },
    ],
    []
  );

  return (
    <div className="left_bar topnav">
      <ul
        className={`myLinks ${isSidebar === true ? "menu-block" : "menu-none"}`}
      >
        {sidebarContent.map((sidebar) => (
          <React.Fragment key={sidebar.pathname}>
            <li>
              <Link
                to={sidebar.pathname}
                className={`${
                  sidebar.activeTab.includes(pageTitle) ? "active" : ""
                }`}
                onClick={() =>
                  sessionStorage.setItem("activeTab", "shoppingAisles")
                }
              >
                <span>
                  <img
                    src={
                      sidebar.activeTab.includes(pageTitle)
                        ? sidebar.img2
                        : sidebar.img
                    }
                    alt={sidebar.img}
                    className="category_select_icon"
                  />
                  {sidebar.name}
                </span>
                <i className="fa-solid fa-angle-right"></i>
              </Link>
            </li>
          </React.Fragment>
        ))}
      </ul>
      <Link to="#" className="icon" onClick={openSidebar}>
        <span></span>
        <span></span>
        <span></span>
      </Link>
    </div>
  );
};

export default SideBar;
