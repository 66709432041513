import produce from "immer";
import {
  GET_NOTIFICATION_LIST,
  GET_NOTIFICATION_LIST_SUCCESS,
  GET_NOTIFICATION_LIST_ERROR,
  // GET_AISLE_LIST,
  // GET_AISLE_LIST_SUCCESS,
  // GET_AISLE_LIST_ERROR,
  ADD_NOTIFICATION,
  ADD_NOTIFICATION_SUCCESS,
  ADD_NOTIFICATION_ERROR,
  SET_NOTIFICATION_LIST,
  SET_NOTIFICATION_STATUS,
  SET_NOTIFICATION_SEARCH,
  SET_NOTIFICATIONS_SORT_FIELD,
  SET_NOTIFICATIONS_SORT_ORDER,
  SET_NOTIFICATIONS_PAGE,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_ERROR
} from "./actionTypes";

const initialState = {
  allNotificationsList: null,
  loading: false,
  error: "",
  totalCount: 0,
  totalPages: 0,
  limit: 5,
  page: 1,
  sortOrder: "",
  sortField: "",
  search: "",
  status: "",
  // aisle_list: null,
  // aisleLoad: false,
  // aisleError: "",
  addNotificationLoad: false,
  deleteNotificationLoad: false
};

const pushNotificationsReducer = produce((state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_NOTIFICATION_LIST:
      return {
        ...state,
        loading: true
      };

    //set ingredients list
    case GET_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        allNotificationsList: payload.data,
        totalCount: payload.metadata.totalCount,
        totalPages: payload.metadata.totalPages,
        // page: payload.metadata.pages,
        limit: payload.metadata.limit,
        count: payload.metadata.count,
        loading: false
      };
    case GET_NOTIFICATION_LIST_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    // set notifications status
    case SET_NOTIFICATION_STATUS:
      return {
        ...state,
        status: payload
      };

    // set Notifications search
    case SET_NOTIFICATION_SEARCH:
      return {
        ...state,
        search: payload
      };
    // set Notifications sortField
    case SET_NOTIFICATIONS_SORT_FIELD:
      return {
        ...state,
        sortField: payload
      };

    // set Notifications sortOrder
    case SET_NOTIFICATIONS_SORT_ORDER:
      return {
        ...state,
        sortOrder: payload
      };
    // set Notifications Page
    case SET_NOTIFICATIONS_PAGE:
      return {
        ...state,
        page: payload
      };
    // case GET_AISLE_LIST:
    //   return {
    //     ...state,
    //     aisleLoad: true,
    //   };
    // case GET_AISLE_LIST_SUCCESS:
    //   return {
    //     ...state,
    //     aisle_list: payload,
    //     aisleLoad: false,
    //   };
    // case GET_AISLE_LIST_ERROR:
    //   return {
    //     ...state,
    //     aisleLoad: false,
    //     aisleError: payload,
    //   };
    case ADD_NOTIFICATION:
      return {
        ...state,
        addNotificationLoad: true
      };
    case ADD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        addNotificationLoad: false
      };
    case ADD_NOTIFICATION_ERROR:
      return {
        ...state,
        addNotificationLoad: false
      };

    case SET_NOTIFICATION_LIST:
      return {
        ...state,
        allNotificationsList: payload
      };

    //delete
    case DELETE_NOTIFICATION:
      return {
        ...state,
        deleteNotificationLoad: true
      };
    case DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        deleteNotificationLoad: false
      };
    case DELETE_NOTIFICATION_ERROR:
      return {
        ...state,
        deleteNotificationLoad: false
      };
    default:
      return state;
  }
});

export default pushNotificationsReducer;
